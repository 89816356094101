<template>
    <div>      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12" class="center">
                    <div class="margen d-flex">
                        <hr class="vr"/>
                        <p class="tblName">Contratos</p>
                    </div>
                    <div id="loading" v-show="isLoading">
                        <v-progress-circular indeterminate :size="100" :width="7" color="blue" ></v-progress-circular> 
                    </div>
                    <v-row v-show="!isLoading" >
                        <v-col cols="6" xs="6" sm="6" md="6" v-for="(item) in itemsEmpresas" >
                            <v-card class="borde-card big">
                                <v-card-text >
                                    <v-row>
                                        <v-col cols="4" xs="4" sm="4" md="4" class="marcoLogo py-0">
                                            <img class="logoImg" :src="item.foto"/>
                                        </v-col>
                                        <v-col cols="7" xs="7" sm="7" md="7" class="contenido py-3">
                                           <div class="d-flex py-2"><i class="material-icons business-icon mr-3">business</i><p class="titulo">{{item.nombre}}</p></div> 
                                            <div class="d-flex py-2"><i class="material-icons phone-icon mr-3">phone</i><p class="text">{{item.telefono_contacto}}</p></div>
                                            <div class="d-flex py-2"><i class="material-icons today-icon mr-3">today</i><p class="text">{{item.vigente}}</p></div>
                                        </v-col>
                                        <v-col cols="1" xs="1" sm="1" md="1" class="py-0" >
                                            <v-btn class="btnRevisar btnNormal" @click="abrirContratos(item)">Revisar</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
//imports

//plugins
import urlImg from '../plugins/Urlimagen';
import Notify from '@/plugins/notify';

//apis
import queries from '@/queries/Empresas';

export default {
    name:"",
    components: {
    },
    data() {
        return {
            itemsEmpresas   : [],
            itemEmpresa : null,
            filters         : {AND:[]},
            isLoading       : true,
            datosLogin      : null,
            rol             : null
        }
    },
    props:{

    },
    watch: {
    },
    computed: {
    },
    methods: {
        abrirContratos(data){
            //realiza el cambio a la vista de redactar contratos, enviando el objeto empresa para la construccion del modulo
            this.$session.remove("empresaContrato");
            this.$session.set('empresaContrato',data);
            this.$router.push("/redactar-contratos-empleados");
        },
        init(){
            //construye el modulo segun el rol del usuario
            this.rol = this.datosLogin.rol.name;
            
            switch (this.rol){
                case 'root':
                    this.$apollo.queries.empresas.skip = false;
                    break;
                case 'admin':
                    this.filters.AND.push({column:"CLIENTE_ID",value:this.datosLogin.cliente_id});
                    this.$apollo.queries.empresas.skip = false;
                    break;
                case 'admin-empresa':
                    this.$apollo.queries.empresa.skip = false;

                    break;
                default:
                    this.$router.push("/dashboard");
                    Notify.Warning("¡Denegado!","Tu Rol no permite el acceso a esta vista.");
            }
        }
    },
    mounted () { 
        this.init();
    },
    created () {
        this.datosLogin = this.$session.get("usuario");
       
    },
    apollo: {
        empresas: {
            query       : queries.empresasTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereCondition : this.filters,
                    numberItems : 100000,
                    numberPage  : 1,
                    fieldOrder  : 'nombre',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                //reasignacion del array empresas para modificar el contenido por default de cada empresa
                let business = [];
                this.isLoading = false;

                let format = this.empresas.data;
                if(this.empresas.data.length != 0){
                    format.forEach( item => {
                        let foto = "";
                        let fecha = item.fecha_contrato;

                        if(item.logo !== null){
                            foto = urlImg.getURL() + item.logo;
                        }else{
                            foto = 'static/bg/cliker_login.png';
                        }

                        item.foto = foto;

                        if(fecha !== null){
                            let año = fecha.split("-");
                            item.vigente = "Vigente desde " + año[0];
                            business.push(item);
                        }else{
                            item.vigente = "Vigente desde ----";
                        }

                    })
                }

                this.itemsEmpresas = business;
            }
        },
        empresa: {
            query       : queries.getEmpresaQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                   id:this.datosLogin.empresa_id
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
               this.abrirContratos(this.empresa);
            }
        },

    }
    
}
</script>

<style scoped>
.borde-card.big{
    padding-top:10px; 
    padding-bottom:10px;
}
.margen {
    height: 48px;
    margin-bottom: 20px;
    margin-left: 1px;
}

#loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.center{
    padding-left:32px;
    padding-right:32px;
    margin-left: auto;
    margin-right: auto;
}
.marcoLogo{
    height: cover;
    border-right: 1.07857px solid #E0E0E0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:20px;
}

.logoImg{
    max-width: 140px !important;
    max-height: 140px;
}

.contenido .text{
   font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12.9429px;
    line-height: 16px;
    color: #4F4F4F;
    margin-top:auto;
    margin-bottom:auto;
}

.contenido .titulo{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12.9429px;
    line-height: 16px;
    color: #333333;
    margin-top:auto;
    margin-bottom:auto;
    width: 300px;
}
.btnRevisar{
    bottom: 16px; 
    right: 23px; 
    position: absolute;
    background: rgba(30, 34, 69, 0.9) !important;
    padding:0px 25px 0px 25px !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.181469px !important;
    color: #F2F2F2 !important;

}
</style>
